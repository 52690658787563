<template>
	<div>
		<div>
			<div class="header">
				<h1>결제방법 변경</h1>
				<button class="icon" v-on:click="close()">
					>
					<i class="ic-24 ic-close"></i>
					close
				</button>
			</div>
			<!--content-->
			<div class="contents-wrap">
				<pay-method
					:saveFlag="saveFlag"
					v-on:setPayMethodInfo="setPayMethodInfo"
					:orderType="orderInfo.baseInfoModel.orderType"
					:isSaas="isSaas"
				></pay-method>
				<div class="group column mt-24 border-top">
					<p class="text-center mt-24">
						선택하신 결제 방법으로 해당 계약건이 변경 결제 됩니다.<br />
						결제 방법을 변경하시겠습니까?
					</p>
				</div>
			</div>
			<!--//content end-->
			<div class="">
				<div class="btn-area group-btn">
					<button class="line" v-on:click="close()">취소</button>
					<button class="solid" v-on:click="save()">변경</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ToastComponent from '@/components/code/modal/ToastComponent';

const paymentConvert = {
	DEPOSIT: 'TRANSPORT',
	CREDITCARD: 'CREDITCARD',
	REALTIMETRANSPORT: 'REALTIME_TRANSPORT',
	AUTOEXTENSION: 'AUTO_CREDITCARD',
};
const paymentDesc = {
	DEPOSIT: '무통장입금',
	CREDITCARD: '신용카드',
	REALTIMETRANSPORT: '실시간 계좌이체',
	AUTOEXTENSION: '자동카드결제',
};
import PayMethod from '@/components/payment/method/PayMethod.vue';
import { payment } from '@/components/apply/mixinsPayment.js';
import { validation } from '@/components/apply/mixinsValidation.js';

export default {
	mixins: [payment, validation],
	props: {
		orderInfo: {},
		totPayAmt: Number,
		isSaas: String,
	},
	data() {
		return {
			saveFlag: false,
			paymentUrl: '/kiwoom/popup',
			payMethodInfo: {},
		};
	},
	components: {
		PayMethod,
	},
	methods: {
		setPayMethodInfo(payMethodInfo) {
			this.saveFlag = false;

			this.payMethodInfo = payMethodInfo;
			this.payMethodInfo['amount'] = this.totPayAmt;
			this.payMethodInfo['orderId'] = this.orderInfo.id;
			this.payMethodInfo['orderSummary'] = this.orderInfo.orderSummary;
			this.payMethodInfo['serviceType'] = this.orderInfo.serviceType;

			if (!this.payMethodInfo.infoConsent) {
				this.openToast('결제 방법 안내 동의가 필요합니다.');
				return;
			}

			this.convertPayType(this.payMethodInfo);
			if (payMethodInfo.payType == 'DEPOSIT') {
				this.close();
			} else if (payMethodInfo.payType == 'AUTOEXTENSION') {
				if (!this.checkAutoExtension(this.payMethodInfo)) {
					return;
				}
				// 자동결제 정보 저장
				this.saveAutoExtensionInfo(this.payMethodInfo, this.orderInfo);
			} else {
				this.goPayment(this.payMethodInfo);
			}

			return;
		},
		async convertPayType(payMethodInfo) {
			if (this.orderInfo.payType == paymentConvert[payMethodInfo.payType]) {
				return;
			}
			this.orderInfo.payType = paymentConvert[payMethodInfo.payType];
			await this.$store.dispatch('changePayType', this.orderInfo);
		},
		async goPayment(payMethodInfo) {
			await this.openPayRequestLayer(payMethodInfo);
			await window.addEventListener(
				'message',
				(event) => {
					if (event.data === 'success') {
						this.close();
					} else if (event.data === 'close') {
						this.$ms.dialog.alert('결제 중 오류가 발생하였습니다.', () => {});
					}
				},
				false,
			);
		},
		async saveAutoExtensionInfo(paymethodInfo, order) {
			const result = await this.setAutoExtension(paymethodInfo, order.id, order.customer.id, order.serviceType); // 자동결제 등록
			this.saveFlag = false;
			if (result) {
				this.close();
			}
		},
		save() {
			this.saveFlag = true;
		},
		close() {
			this.$emit('close');
		},
		openToast(text) {
			this.$modal.show(
				ToastComponent,
				{
					toastText: text,
				},
				{
					width: '800px',
					height: '80px',
					draggable: true,
				},
				{
					closed: this.closeModal,
				},
			);
		},
	},
};
</script>

<style scoped>
.com_layer_normal {
	z-index: 999;
}
#popupLayer {
	z-index: 9999;
}
.doms_overflow {
	height: 600px;
	overflow-y: scroll;
}
</style>
